import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouteGuard implements CanActivate {
    constructor(
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = null;
        user = JSON.parse(localStorage.getItem('user'));
        if (!!user) {
            // authorised so return true
            return true;
        }
        this.router.navigate(['/welcome'], { queryParams: { returnUrl: 'unauthorized' }});
        return false;
    }
}