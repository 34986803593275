import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from './welcome/services/access.guard';
import { RouteGuard } from './welcome/services/route.gard';

const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(mod => mod.WelcomeModule),
    canActivate: [AccessGuard]
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
    canActivate: [RouteGuard]
  },
  {
    path: '*',
    redirectTo: '/welcome'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
