import { Component } from '@angular/core';
import { initializeApp } from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Coffeedream';
  firebaseConfig = {
    apiKey: "AIzaSyBM2eubDPINsy27UEOPKXgmhXr767QF89Y",
    authDomain: "coffeedream-b2e9a.firebaseapp.com",
    projectId: "coffeedream-b2e9a",
    storageBucket: "coffeedream-b2e9a.appspot.com",
    messagingSenderId: "503645766780",
    appId: "1:503645766780:web:183593e34d1b00cf0e35f9",
    measurementId: "G-7W3K3PY03X"
  };

  constructor() {
    const app = initializeApp(this.firebaseConfig);
  }
}
